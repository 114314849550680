import React from "react";
import { useRecoilValue } from "recoil";

import { customerAtom } from "../../atoms/customer";
import {
  greenCircleCheck,
  blackCirclePlus
} from "../../icons";

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function(txt){
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export default function ViewPlans() {
  const customer = useRecoilValue(customerAtom);  

  const createProducts = (products) => {
    // Sorts Products alphabetically
    const sortedProducts = [...products];
    sortedProducts.sort((a,b) => a.name.localeCompare(b.name));

    return sortedProducts.map((thisProduct) => {
      return (
        <div key={thisProduct.id} className={`pro-row p-3 align-items-center`}>
          <div className="d-flex align-items-center">
            {thisProduct.icon}
            <span className="plan-item">{toTitleCase(thisProduct.name)}</span>
          </div>
          <div>
            {greenCircleCheck}
          </div>
        </div>
      );
    })
  }

  const createAddons = (addons) => {
    // Sorts Addons alphabetically
    const sortedAddons = [...addons];
    sortedAddons.sort((a,b) => a.product.name.localeCompare(b.product.name));

    return sortedAddons.map((thisAddon) => {
      return (
        <div key={thisAddon.product.id} className={`bg-white pro-row p-3 align-items-center`}>
          <div className="d-flex align-items-center">
            {thisAddon.icon}
            <span className="plan-item">{toTitleCase(thisAddon.product.name)}</span>
          </div>
          <div>
            {blackCirclePlus}
          </div>
        </div>
      );
    })
  }

  const createPlanCard = (
    plan,
    color
  ) => {
    return (
      <div key={plan.id} className={`py-2 col rounded-4 pro bg-transparent`} id={plan.ext_reference_id}>
        <div style={{backgroundColor: `${color}`}} className="pro-row rounded-top-4 justify-content-center p-3 position-relative">
          <h2 className="pb-0 text-white p-0" data-testid={`${plan.ext_reference_id}-title`}>
            {plan.name}
          </h2>
        </div>

        <div className='bg-white rounded-bottom-4'>
          <div className="pro-row">
            <div className="col-6">
              <h2 className="price mb-0 mt-1">${plan.initialMax}</h2>
              <p className="mb-2 price-desc">Initial Treatment</p>
            </div>
            <div className="col-6 bg-plan ">
              <h2 className="price mb-0 mt-1">${plan.recurringMax}</h2>
              <p className="mb-2">Per Service</p>
            </div>
          </div>

          <div className="bg-plan rounded-bottom-4">
            {createProducts(plan.products)}
            {createAddons(plan.addons)}

            <div className="mx-4 border-bottom separate"></div>

            <div className="pro-row bg-white p-3 align-items-baseline  rounded-bottom-4">
              <div className="d-flex">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-2">
                  <g clipPath="url(#clip0_1322_2601)">
                    <path
                      d="M21 3H18V1H16V7H18V5H21V20H3V5H5V3H3C1.896 3 1 3.895 1 5V20C1 21.104 1.896 22 3 22H21C22.104 22 23 21.104 23 20V5C23 3.895 22.104 3 21 3Z"
                      fill="black"
                    />
                    <path d="M8 5H15V3H8V1H6V7H8V5Z" fill="black" />
                    <path d="M9 9H5V13H9V9Z" fill="black" />
                    <path d="M14 9H10V13H14V9Z" fill="black" />
                    <path d="M19 9H15V13H19V9Z" fill="black" />
                    <path d="M9 14H5V18H9V14Z" fill="black" />
                    <path d="M14 14H10V18H14V14Z" fill="black" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1322_2601">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

                <div className="plan-item">
                  Treatment Frequency
                  <p className="m-0 fst-normal">Apr - Oct: Every 30-60 days</p>
                  <p className="m-0 fst-normal">Nov - Mar: 50 -80 days</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (    
    <div className={`view-plans-component row`}>
      {customer.plans.map((thisPlan, idx) => {
        // Alternate colors
        const color = idx % 2 ? "#0D210F" : '#344C38';
        return createPlanCard(thisPlan, color);
      })}
    </div>    
  );
}
